import { useAuth } from '@api'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { UrlObject } from 'url'
import Header from './layout/Header'
import Sidebar from './layout/Sidebar'

const Layout: FC<{
  children: React.ReactNode
  title?: string
  companyName?: string
  headerChildren?: React.ReactNode
  filterSectionChildren?: React.ReactNode
  isBack?: boolean
  backLink?: string | UrlObject
  footerChildren?: React.ReactNode
}> = ({
  title,
  children,
  companyName,
  headerChildren,
  filterSectionChildren,
  isBack,
  backLink,
  footerChildren,
}) => {
  const router = useRouter()

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const sidebarRef = useRef<HTMLDivElement | null>(null)

  const authQuery = useAuth()

  useEffect(() => {
    if (authQuery.isIdle) {
      router.replace('/login')
    }
  }, [authQuery.data])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsSidebarOpen(false)
    }
  }

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={classnames(
        'grid grid-cols-1 md:grid-cols-[80px_minmax(450px,_1fr)] bg-background-200',
      )}
    >
      <Sidebar
        closeSidebar={() => setIsSidebarOpen(false)}
        ref={sidebarRef}
        className={classnames(
          'absolute top-0 left-0 h-full w-20 z-50 transform md:relative md:translate-x-0 transition-transform duration-300 ease-in-out',
          {
            '-translate-x-full': !isSidebarOpen,
            'translate-x-0': isSidebarOpen,
          },
        )}
      />

      <div className="flex flex-col h-dvh">
        <Header
          toggleSidebar={toggleSidebar}
          title={title}
          companyName={companyName}
          headerChildren={headerChildren}
          filterSectionChildren={filterSectionChildren}
          isBack={isBack}
          backLink={backLink}
        />

        <main className="h-full w-full overflow-auto">{children}</main>

        {footerChildren && (
          <footer className="bg-white shadow-rotated-lg py-2 px-4 w-full flex justify-center sm:justify-end">
            {footerChildren}
          </footer>
        )}
      </div>
    </div>
  )
}

export default Layout
