export const I18N_LANGUAGES = [
  'en',
  'ru',
  'uk',
  'ge',
  'lv',
  'lt',
  'sr',
] as const

export type I18nLanguage = (typeof I18N_LANGUAGES)[number]
